// CookiePopup.js
import React, { useState, useEffect } from 'react';


const CookiePopup = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const hasUserAcceptedCookies = localStorage.getItem('acceptCookies');
        if (!hasUserAcceptedCookies) {
            setIsVisible(true);
        }
    }, []);

    const handleAcceptCookies = () => {
        localStorage.setItem('acceptCookies', 'true');
        setIsVisible(false);
    };

    return (
        isVisible && (
            <div className="cookie-popup">
                <p>This site uses cookies. By continuing to use this site, you accept our use of cookies.</p>
                <button onClick={handleAcceptCookies}>Accept</button>
            </div>
        )
    );
};

export default CookiePopup;
