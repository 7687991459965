import Header from "../components/Header";
import React from "react";
import Container from '@mui/material/Container';

const Home = (props) => (
        <>
            <Header title="Home" sections={props.sections}/>
            <Container>
                <h1>Welcome to Emblazoned Industries – Your 2A Lifestyle Headquarters!</h1>

                <p>At Emblazoned Industries, we're passionate about preserving your right to bear arms and embracing the 2nd
                    Amendment lifestyle. Step into a world where quality meets responsibility, and your firearms journey is
                    met
                    with expertise and integrity. Conveniently located in Amsterdam, NY, Emblazoned Industries is your
                    premier supplier for firearms and more.</p>

                <h2>🔥 Explore Our Premium Selection:</h2>
                <p>Discover a curated collection of firearms, ammunition, accessories, and gear tailored to enthusiasts,
                    hunters, and defenders of freedom. We pride ourselves on offering top-tier products from trusted
                    brands
                    to elevate your shooting experience. <a href="https://shop.emblazonedindustries.com">[Shop Now]</a>
                </p>

                <h2>🎓 Knowledge is Power:</h2>
                <p>Whether you're a seasoned pro or a first-time gun owner, Emblazoned Industries is your go-to resource
                    for
                    education and training. Join our workshops, safety courses, and events designed to empower you with
                    the
                    knowledge needed for responsible firearm ownership. <a href="/calendar">[Course Calendar]</a></p>

                <h2>🛡️ Safety First, Always:</h2>
                <p>We prioritize safety above all else. Our team is committed to guiding you through the selection process,
                    ensuring you find the right equipment for your needs, while promoting responsible gun ownership.</p>

                <h2>🤝 Community Matters:</h2>
                <p>As proud members of Amsterdam NY, we believe in supporting local initiatives. Emblazoned Industries is
                    not just a guns and ammo store; it's a hub where like-minded individuals come together. Stay tuned for
                    community events, sponsorships, and partnerships that strengthen our shared values.</p>

                <h2>📦 Shop with Confidence:</h2>
                <p>Enjoy a seamless shopping experience with secure transactions and prompt deliveries. Your
                    satisfaction is
                    our priority, and we stand by the quality and authenticity of every product we offer. <a
                        href="https://shop.emblazonedindustries.com">[Shop Now]</a></p>

                <h2>🗝️ Become a Member:</h2>
                <p>We offer various levels of membership for different budgets and needs. <a href="/membership">Become
                    a member</a> today.</p>
                <h2>♻️ Consignment:</h2>
                <p>
                    Looking to trade up or sell your firearm? <a href="/contact">Contact us</a> about <a
                    href="/consignment">consignment</a>.
                </p>
                <hr/>
                <p>Join us in celebrating the 2nd Amendment – where freedom, responsibility, and quality unite. Browse our
                    collection, attend our events, and let's embark on this journey together.</p>


            </Container>
        </>
    )
;
export default Home;