import Header from "../components/Header";
import React from "react";
import Container from '@mui/material/Container';

const Links = (props) => (
    <>
        <Header title="Links and Resources" sections={props.sections}/>
        <Container>
            <div>
                <a href="https://www.facebook.com/profile.php?id=100083463753799">Follow us on Facebook</a><br/><br/>
                <a href="https://shop.emblazonedindustries.com">Visit our online store</a><br/><br/>
                <a href="/resources/final-nysp-dcjs-minimum-standards-for-firearm-safety-training-8-23-22.pdf">Minimum
                    standards for New York State conceald carry firearm safety training</a>
            </div>
        </Container>
    </>
);

export default Links;