export function convertDateFormat(inputDate) {
    //const year = inputDate.substring(0, 4);
    const month = inputDate.substring(4, 6);
    const day = inputDate.substring(6, 8);

    // Construct a Date object with the parsed components
    /*const parsedDate = new Date(`${month}/${day}/${year}`);

    // Use Intl.DateTimeFormat to format the date as MM/DD/YYYY
    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    }).format(parsedDate);*/

    return month.concat("/",day)
}

export function isDateInFuture(inputDate) {
    const year = inputDate.substring(0, 4);
    const month = inputDate.substring(4, 6);
    const day = inputDate.substring(6, 8);
    const parsedDate = new Date(`${month}/${day}/${year}`);
    const now = new Date();
    now.setHours(0,0,0,0);
    return now <= (parsedDate);
}