import * as React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';

function Header(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const {sections, title} = props;

    return (
        <React.Fragment>
            <Toolbar
                sx={{borderBottom: 1, borderColor: 'divider', position: 'sticky', top: 0, backgroundColor: 'white'}}>
                <Typography
                    component="h2"
                    variant="h5"
                    color="inherit"
                    align="center"
                    sx={{flex: 1}}
                >
                    <div><img src="/image/emblazoned_logo1.svg" alt="Emblazoned Industries Logo" width="85%"/></div>
                    <span className="lg-view">Emblazoned Industries{title.length > 0 ? ' : '.concat(title) : ""}</span>
                    <span className="sm-view">{title}</span>

                </Typography>
                <IconButton onClick={handleClick}><MenuIcon/></IconButton>
                <Menu id="long-menu" MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      slotProps={{
                          paper: {
                              style: {
                                  width: '22ch',
                              }
                          },
                      }}
                >
                    {sections.map((section) => (
                        <MenuItem key={section.title} onClick={handleClose}>
                            <Link
                                color="inherit"
                                noWrap
                                key={section.title}
                                variant="body2"
                                href={section.url}
                                sx={{p: 1, flexShrink: 0}}
                            >
                                {section.title}
                            </Link>

                        </MenuItem>
                    ))}
                </Menu>

            </Toolbar>

        </React.Fragment>
    );
}

Header.propTypes = {
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }),
    ).isRequired,
    title: PropTypes.string.isRequired,
};

export default Header;
