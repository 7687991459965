import Header from "../components/Header";
import React from "react";
import Container from '@mui/material/Container';

const Membership = (props) => (
    <>
        <Header title="Membership And Loyalty Programs" sections={props.sections}/>
        <Container>
            <div>
                <h1>Membership Levels</h1>

                <h2>Ember:</h2>
                <p>
                    $1.99 per month plus a $5 activation.
                    <ul>
                        <li>You're automatically entered in drawings for a $25 gift card.</li>
                    </ul>
                    <a href="https://subscriptions.helcim.com/subscribe/foqzq0tq8wfh6wdx24aoakycfx2ai">Sign Up</a>
                </p>
                <hr/>
                <h2>Alight:</h2>
                <p>
                    $14.99 per month plus a $5 activation.
                    <ul>
                        <li>5% off all firearms.</li>
                        <li>5% off ammo.</li>
                        <li>5% off training classes.</li>
                    </ul>
                    <a href="https://subscriptions.helcim.com/subscribe/f8b0erb2o5t0laq1zqladsj3n4bes">Sign Up</a>
                </p>
                <hr/>
                <h2>Blaze:</h2>
                <p>
                    $24.99 per month plus a $5 activation.
                    <ul>
                        <li>8% off all firearms.</li>
                        <li>Every tenth box of ammo is free.<sup>*</sup></li>
                        <li>10% off training classes.</li>
                        <li>Featured listing for one item on consignment.</li>
                        <li>NYS NICS transfer fees for ammunition are waived, once per month.</li>
                    </ul>
                    <a href="https://subscriptions.helcim.com/subscribe/fj8u20kvdv1hmdk9g4fafuulagx6q">Sign Up</a>

                </p>

                <hr/>
                <h2>Pyre:</h2>
                <p>
                    <strong>Only available to Law Enforcement and Emergency First Responders.</strong><br/>
                    $19.99 per month with no activation fee.
                    <ul>
                        <li>All the same perks as Blaze</li>
                    </ul>
                    <a href="https://subscriptions.helcim.com/subscribe/f2ttb4k0aflccakd59ovlwvaxa6ob">Sign Up</a>
                </p>
                <hr/>
                <h2>Inferno:</h2>
                <p>$39.99 per month plus a $10 activation.
                    <ul>
                        <li>10% off firearms.</li>
                        <li>Every ninth box of ammo is free.<sup>*</sup></li>
                        <li>15% off training classes for you and up to one guest.</li>
                        <li>Premium listings for all items on consignment.</li>
                        <li>NYS NICS transfer fees for ammunition and firearms are waived.</li>
                    </ul>
                    <a href="https://subscriptions.helcim.com/subscribe/fixipkbxwoqhvxhpso4u2ev4u04y4">Sign Up</a>
                </p>
                <hr/>
                <small>*Free ammo will be the lowest cost of the boxes purchased.</small>
            </div>
        </Container>
    </>
);
export default Membership;