import {useEffect, useState} from 'react';

const SignupForm = (setShowForm, setSubmitInProgress, setShowPaymentLink, setCheckoutToken) => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        courseId: -1,
    });

    const [error, setError] = useState('Please fill out all fields');
    const [result, setResult] = useState(null);

    useEffect(() => {
        //console.log('useEffect called');
        //console.log('formData:' + JSON.stringify(formData))
        validateFields();
    }, [formData]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: name === 'phone' ? formatPhoneNumber(value) : value,
        }));

    };

    const validateNotEmpty = (text) => {
        const textRegex = /^.{3,}$/;
        return textRegex.test(text);
    };

    const validateEmail = (email) => {
        // Simple email validation using a regular expression
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const formatPhoneNumber = (input) => {
        // Remove non-numeric characters
        const numericInput = input.toString().replace(/\D/g, '');
        // Use a regular expression to format the phone number
        return validatePhoneNumber(input) ? numericInput.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : input;
    };

    const validatePhoneNumber = (input) => {
        // Use a regular expression to check if the phone number is valid
        return /^[2-9]\d{9}$/.test(input.toString().replace(/\D/g, ''));
    };

    const validateFields = () => {
        //console.log("validateFields called")
        if (!validateNotEmpty(formData.name) || !validateNotEmpty(formData.message)) {
            setError('Please fill out all fields');
            return false;
        }
        if (!validatePhoneNumber(formData.phone)) {
            setError('Please enter a valid phone number');
            return false;
        }
        if (!validateEmail(formData.email)) {
            setError('Please enter a valid email address');
            return false;
        }
        if (formData.courseId < 0) {
            setError('Please select a course');
            return false;
        }
        setError('');
        return true;
    }

    const handleSubmit = () => {
        setSubmitInProgress(true);
        if (!validateFields()) {
            return false;
        }

        const apiUrl = 'https://xpi3nus5yrgs53pv5mhvsvtzpm0zwfto.lambda-url.us-east-1.on.aws/';
        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                //console.log('Server response:', data);
                let checkoutToken = data.checkoutToken;
                let secretToken = data.secretToken;
                let customerGuid = data.guid;
                //console.log('Checkout Token:', checkoutToken);
                setResult('Thank You.  Your course sign-up has been recorded.  Please complete the payment form.');
                setShowForm(false);
                setShowPaymentLink(true);
                //console.log('adding event listener');
                window.addEventListener('message', (event) => {
                    const helcimPayJsIdentifierKey = 'helcim-pay-js-' + checkoutToken;
                    if (event.data.eventName === helcimPayJsIdentifierKey) {
                        if (event.data.eventStatus === 'ABORTED') {
                            console.error('Transaction failed!', event.data.eventMessage);
                        }
                        if (event.data.eventStatus === 'SUCCESS') {
                            setShowPaymentLink(false);
                            validateResponse(event.data.eventMessage)
                                .then(response => {
                                    //console.log(response);
                                    if (!response.ok) {
                                        throw new Error(`HTTP error! Status: ${response.status}`);
                                    }
                                    setResult('Thank You.  Your course sign-up and payment have been recorded.');
                                })
                                .catch(err => {
                                    console.error(err);
                                    setResult('An Error has occurred.  Please contact us to complete your registration.');
                                }).finally(() => {
                                setSubmitInProgress(false);
                            });
                        }
                    }
                });

                function validateResponse(eventMessage) {
                    setResult('Your payment is being recorded, please wait.');
                    setSubmitInProgress(true);
                    const payload = {
                        'rawDataResponse': eventMessage,
                        'secretToken': secretToken,
                        'courseId': formData.courseId,
                        'guid': customerGuid,
                    };
                    // console.log(payload);
                    return fetch('https://paobn2hl7bg23bipuxo4tfioyi0wkiok.lambda-url.us-east-1.on.aws/', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(payload)
                    });
                }

                setCheckoutToken(checkoutToken);
                appendHelcimPayIframe(checkoutToken);
            })
            .catch(error => {
                console.error('Error:', error);
                setError('An Error has occurred.  Please try again later or contact us.');
            })
            .finally(() => {
                setSubmitInProgress(false);
            })

    };

    return {formData, handleChange, handleSubmit, validateFields, error, result};
};

export default SignupForm;
