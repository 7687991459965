import Header from "../components/Header";
import CourseList from "../components/CourseList"
import React, {useEffect, useState} from "react";
import Container from '@mui/material/Container';
import SignupForm from "../components/SingupForm";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ExpandableTableRow from "../components/ExpandableTableRow";

const Calendar = (props) => {

    const [showForm, setShowForm] = useState(false);
    const [showPaymentLink, setShowPaymentLink] = useState(false);
    const [submitInProgress, setSubmitInProgress] = useState(false);
    const [checkoutToken, setCheckoutToken] = useState('');
    const {
        formData,
        handleChange,
        handleSubmit,
        validateFields,
        error,
        result
    } = SignupForm(setShowForm, setSubmitInProgress, setShowPaymentLink, setCheckoutToken);
    const {courses, coursesFetched} = CourseList();
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [selectedCourseName, setSelectedCourseName] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showCourses, setShowCourses] = useState(false);


    const handleRadioChange = (courseId, courseName) => {
        //console.log("handleRadioChange called")
        setSelectedCourseName(courseName);
        formData.courseId = courseId
        setShowForm(true);
        //console.log('formData:' + JSON.stringify(formData))
        //console.log("formData.courseId:" + formData.courseId)
        //console.log("courseId:" + courseId)
        //console.log("equal: " + (formData.courseId === courseId))
        //console.log("handleRadioChange completed")
        // validateFields();
    };

    const handleButtonClick = () => {
        setIsSubmitDisabled(true);
        handleSubmit();
    };

    const handlePaymentClick = () => {
        appendHelcimPayIframe(checkoutToken);
    };

    useEffect(() => {
        //console.log('data changed');
        if (!error) {
            setIsSubmitDisabled(false);
        } else {
            setIsSubmitDisabled(true);
        }

    }, [error]);
    useEffect(() => {
        if (coursesFetched) {
            //console.log('courses loaded.  found ' + courses.length);
            if (courses.length > 0) {
                setShowCourses(true);
            }
            setLoading(false);
        }
    }, [courses]);

    return (
        <>
            <Header title="Course Calendar" sections={props.sections}/>
            <Container>
                <h1 className="text-center">Upcoming Courses</h1>
                {loading ? (
                    <Box className="classes-table">
                        <CircularProgress className="classes-table"/>
                    </Box>
                ) : (
                    !showCourses ? (<p className="text-center">No courses found. Please check back later.</p>) : (
                        <>
                            <div className="full-width-centered">
                                <table>
                                    <thead>
                                    <tr key="header">
                                        <th/>
                                        <th className="left-align">Course Name</th>
                                        <th className="left-align">Date</th>
                                        <th className="right-align">Total Cost</th>
                                        <th className="right-align" style={{minWidth: "4rem"}}>Sign up</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {courses.map((course, index) => (
                                        <ExpandableTableRow className={index % 2 === 0 ? 'even-row' : 'odd-row'}
                                                            key={course.course_id} course={course} formData={formData}
                                                            handleRadioChange={handleRadioChange}/>

                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            {showForm ? (
                                <>
                                    <hr/>
                                    <div>
                                        <p className="classes-table">Selected Course: {selectedCourseName}</p>
                                        <form onSubmit={handleSubmit}>
                                            <label>
                                                Name:
                                                <input type="text" name="name" className="full-width"
                                                       value={formData.name}
                                                       onChange={handleChange}/>
                                            </label>
                                            <label>
                                                Phone Number:
                                                <input type="text" name="phone" className="full-width"
                                                       value={formData.phone}
                                                       onChange={handleChange}/>
                                            </label>
                                            <label>
                                                Email:
                                                <input type="text" name="email" className="full-width"
                                                       value={formData.email}
                                                       onChange={handleChange}/>
                                            </label>
                                            <button type="button" onClick={handleButtonClick}
                                                    disabled={isSubmitDisabled}>Submit
                                            </button>
                                            {error && <p style={{color: 'red'}}>{error}</p>}
                                        </form>
                                    </div>
                                </>) : ('')}

                            <div className="classes-table">{(!error || result) && <p>{result}</p>}
                                {showPaymentLink && <p><a href="#" onClick={handlePaymentClick}>
                                    Pay Now
                                </a><br/></p>}
                                {submitInProgress &&
                                    <Box className="classes-table">
                                        <CircularProgress className="classes-table"/>
                                    </Box>
                                }
                            </div>


                            <br/>
                            <div>Pay a non-refundable deposit now and the balance at the start of the class. <a href="/membership">Members
                                discounts</a> taken off the total cost as the start of the class.
                            </div>
                        </>
                    )
                )}
            </Container>
        </>
    )
        ;
};

export default Calendar;