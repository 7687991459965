import Header from "../components/Header";
import React from "react";
import Container from '@mui/material/Container';

const Consignment = (props) => (
    <>
        <Header title="Consignment" sections={props.sections}/>
        <Container>
            <div>
                <h1>🔒 Secure and Hassle-Free Firearm Consignment Services</h1>
                <p>Are you looking to sell or upgrade your firearms? Our trusted consignment service offers a secure and
                    seamless process for firearm enthusiasts like you.</p>

                <h2>Why Choose Our Consignment Service:</h2>
                <div>
                    <ul>
                        <li>Expert Evaluation: Our team of experienced firearm experts will assess the condition and
                            value
                            of your firearms to ensure you receive a fair and accurate appraisal.
                        </li>

                        <li>Wide Exposure: Benefit from our extensive network and online presence. Your firearms will be
                            showcased to a broad audience of potential buyers, increasing the chances of a successful
                            sale.
                        </li>

                        <li>Legal Compliance: We prioritize compliance with all local, state, and federal regulations
                            regarding firearm sales. Rest assured that every transaction will adhere to the highest
                            legal
                            standards.
                        </li>

                        <li>Confidentiality: Your privacy and security are our top priorities. We handle all
                            transactions
                            discreetly and professionally.
                        </li>
                    </ul>
                </div>
                <h2>How Our Consignment Process Works:</h2>

                <div>
                    <ul>
                        <li>Evaluation: Bring your firearms to our location for a comprehensive evaluation.</li>

                        <li>Agreement: Once the terms are agreed upon, we'll sign a consignment agreement outlining the
                            details of the arrangement.
                        </li>

                        <li>Marketing: We'll actively market your firearms through our various channels, ensuring
                            maximum
                            visibility.
                        </li>

                        <li>Sale and Transfer: When a buyer is found, we handle the legal transfer process, ensuring a
                            smooth and lawful transaction.
                        </li>
                    </ul>
                </div>

                <h2>Contact Us Today:</h2>
                <p>
                    Ready to consign your firearms with us? <a href="/contact">Contact our team</a> to schedule an
                    evaluation or to inquire about our consignment process. Trust us to handle your firearms with the
                    utmost
                    professionalism and care
                </p>
            </div>
        </Container>
    </>
);
export default Consignment;