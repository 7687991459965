import Header from "../components/Header";
import React from "react";
import Container from '@mui/material/Container';

const Training = (props) => (
    <>
        <Header title="Training and Education" sections={props.sections}/>
        <Container>
            <div>
                <p><a href="/calendar">View our calendar</a> of upcoming training courses.</p>
                <h3>CCW Required Training for New York State</h3>
                <p><a href="/contact">Contact us</a> for details regarding meeting the training requirements necessary
                    to obtain your CCW.</p>
                <p>We're currently approved to provide training in the following counties, with more coming soon:</p>
                <ul>
                    <li>Albany County</li>
                    <li>Montgomery County</li>
                    <li>Schoharie County</li>
                    <li>Saratoga County</li>
                    <li>Rensselaer County</li>

                </ul>
                <h3>Additional Training</h3>
                <p>Embark on a transformative learning experience with Emblazoned Industries 2A Training and Education.
                    Our mission is to equip enthusiasts with the knowledge, skills, and confidence needed to navigate
                    the world of firearms responsibly.</p>
                <p>Whether you're a beginner seeking foundational training or an advanced shooter aiming for mastery,
                    our comprehensive programs cater to all skill levels. Explore topics ranging from firearm safety
                    essentials to advanced marksmanship techniques, all tailored to your individual goals.</p>
                <p>Benefit from the expertise of our seasoned instructors who are passionate about sharing their
                    knowledge. Our courses are designed to guide you through a progressive curriculum, ensuring a
                    thorough understanding of firearm mechanics, safety protocols, and legal considerations.</p>
                <p>Prioritize safety with our rigorous safety courses, emphasizing responsible firearm handling and
                    storage practices. Stay informed on current regulations and obtain certifications that align with
                    industry standards, empowering you to be a responsible and law-abiding firearm owner.</p>
                <p>Join a community of like-minded individuals dedicated to continuous improvement. Emblazoned
                    Industries offers access to exclusive workshops, seminars, and events, connecting you with top-tier
                    instructors and fellow enthusiasts who share your passion.</p>
                <p>Discover the joy of continual growth in your firearms expertise. Emblazoned Industries Training and
                    Education is committed to providing a supportive environment where you can focus on refining your
                    skills and expanding your knowledge.</p>
                <p><a href="/contact">Contact us</a> today to enroll in a personalized training program that will
                    elevate your understanding of firearms, fostering a safe, skilled, and responsible community.
                </p>
            </div>
        </Container>
    </>
);

export default Training;