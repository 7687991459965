import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            Copyright 2024 Emblazoned Industries
        </Typography>
    );
}

function Footer() {

    return (
        <Box component="footer" sx={{bgcolor: 'background.paper', py: 6}}>
            <Container maxWidth="lg">
                <p align="center">Take a shot with us. We assure you we'll hit the target.</p>
                <div className="text-center">🌐 <a href="https://shop.emblazonedindustries.com">[Shop Now]</a> <a
                    href="/contact">[Contact
                    Us]</a> <a
                    href="/about">[Learn
                    More]</a> <a href="https://www.facebook.com/profile.php?id=100083463753799">[Follow us on
                    Facebook]</a>
                </div>
                <Copyright/>
            </Container>
        </Box>
    );
}

export default Footer;
