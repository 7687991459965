import Header from "../components/Header";
import React from "react";
import Container from '@mui/material/Container';
const Services = (props) => (
    <>
        <Header title="Services" sections={props.sections}/>
        <Container>
            <div>
                <h2>Introducing Emblazoned Industries Concierge Services</h2>
                <p>Elevate your firearms experience with our exclusive 2nd Amendment concierge services. At Emblazoned Industries, we understand the unique needs of firearm enthusiasts and prioritize your safety, security, and satisfaction.</p>
                <h3>Tailored Solutions for Every Gun Enthusiast</h3>
                <p>Whether you're a seasoned collector, a first-time gun owner, or a shooting sports enthusiast, our dedicated concierge team is here to streamline your journey. From firearm selection and customizations to secure storage solutions, range reservations and more, we've got you covered.</p>
                <h3>Personalized Assistance Every Step of the Way</h3>
                <p>Experience the ease and comfort of having your own dedicated advisor who will expertly navigate you through the complexities of regulations, licensing, and paperwork. Our team of specialists is readily available to support you with permit applications, ensuring legal compliance, and every other facet of your journey towards exercising your 2nd Amendment rights.</p>
                <h3>Safety First, Always</h3>
                <p>Your safety is our top priority. Benefit from our comprehensive safety training programs, ensuring you are well-versed in proper handling, storage, and usage of firearms.</p>
                <h3>Experience the Pinnacle of 2A Luxury</h3>
                <p>Emblazoned Industries Concierge Services is not just a service; it's a lifestyle. Gain access to exclusive events, VIP range experiences, and a network of like-minded individuals who share your passion for firearms.</p>
                <h3>Your Peace of Mind is Our Mission</h3>
                <p>Discover a new level of convenience and peace of mind with Premier 2A. Let us handle the details, so you can focus on enjoying your firearms responsibly and confidently.</p>
                <p>
                    <a href="/contact">Contact us</a> today to embark on a personalized journey through the world of firearms, where luxury meets liberty.</p>

            </div>
        </Container>
    </>
);

export default Services;