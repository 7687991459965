import Header from "../components/Header";
import React from "react";
import Container from '@mui/material/Container';

const About = (props) => (
    <>
        <Header title="About" sections={props.sections}/>
        <Container>
            <div><img src="/image/emblazoned_square.svg" alt="Emblazoned Industries Guns And Ammo"/></div>
            <div>
                <h3>About Emblazoned Industries</h3>
                <p>At Emblazoned Industries, we proudly hail from the heart of Amsterdam, New York. Since our
                    establishment in 2022, our mission has been crystal clear: to become your trusted hometown 2nd
                    Amendment headquarters.</p>

                <h3>Who we are</h3>
                <p>We are a dedicated manufacturer and dealer of firearms and Special Occupational Tax (SOT) items,
                    committed to delivering quality and precision in everything we do.</p>

                <h3>Our Commitment To You</h3>
                <p>At Emblazoned Industries, our hallmark is top-tier customer service. We understand that each customer
                    has unique needs and preferences, and we're here to cater to them with unwavering dedication. Our
                    team is always ready to assist you, whether you're a seasoned enthusiast or new to the world of
                    firearms.</p>

                <h3>Our Founder's Vision</h3>
                <p>Our journey began under the guidance of a passionate NRA Instructor who saw the need for a trusted
                    source of firearms and related services in our community. With a deep commitment to safety and
                    responsible firearm ownership, we are dedicated to upholding the highest standards in all that we
                    offer.</p>

                <h3>What's Next</h3>
                <p>We're just getting started! Stay tuned as we gear up to provide comprehensive training courses and a
                    range of additional services to empower you with knowledge, skills, and the confidence needed for
                    responsible firearm ownership.</p>

                <p>Join us at Emblazoned Industries as we continue to evolve and serve our community with pride and
                    integrity. We're here to be your steadfast partner in all things related to the 2nd Amendment.</p>

                <p>Thank you for choosing Emblazoned Industries as your trusted firearms and SOT items provider.
                    Together, we're building a safer, more informed, and empowered community.</p>
                <p>Emblazoned Industries is a Federal Firearms Licensed dealer and manufacturer of firearms. Want to
                    know more? <a href="/contact">Contact us</a>.</p>
            </div>
        </Container>
    </>
);

export default About;