// src/components/CourseList.js

import React, {useEffect, useState} from 'react';

const courseUrl = 'https://tkwv4he46xnfe22bbzle4lioum0oekou.lambda-url.us-east-1.on.aws/'
const CourseList = () => {
    const [courses, setCourses] = useState([]);
    const [coursesFetched, setCoursesFetched] = useState(false);

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await fetch(courseUrl);
                if (!response.ok) {
                    throw new Error('Failed to fetch courses');
                }

                const coursesData = await response.json();
                setCoursesFetched(true);
                setCourses(coursesData);
            } catch (error) {
                console.error('Error fetching courses:', error);
                setCoursesFetched(true);
            }
        };

        fetchCourses();
    }, []);

    return {courses, coursesFetched};
};

export default CourseList;
